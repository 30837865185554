import styles from "./User.module.css";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getIsLoggedIn,
    getLoggedInError,
    getLoggedInStatus,
    USER_LOGIN_STATUS_FAILED,
    userLogin
} from "./userSlice";

import {
    DEMO_USER_DA,
    DEMO_USER_DBA,
    DEMO_USER_1,
    DEMO_USER_2,
    P, loggerDebug
} from "../../component/utils";
import {Alert, Button, Container, Form, Dropdown, DropdownButton} from "react-bootstrap";


export function Login() {
    const {t} = useTranslation()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loginStatus = useSelector(getLoggedInStatus)
    const loginError = useSelector(getLoggedInError)
    const isLoggedIn = useSelector(getIsLoggedIn)

    const [loginUser, setLoginUser] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        } else {
            setLoginUser(DEMO_USER_DA)
        }
    }, [isLoggedIn, navigate]);

    function login(event) {
        event.preventDefault();

        const username = event.target.username.value;
        const password = event.target.password.value;

        setLoginUser(username)
        dispatch(userLogin({user:username, pwd:password}));
    }

    const handleDropdown = (eventKey) => {
        setLoginUser(eventKey);
    }

    return (
        <Container className={styles.userForm + " d-flex align-items-center justify-content-center"} style={{ minHeight: "80vh" }}>
            <div className="w-100" style={{maxWidth: "400px"}}>
                <h1 className="text-center mb-4">{t('login')}</h1>
                <Form onSubmit={login} className="d-grid gap-3">
                    {/*<Form.Group>*/}
                    {/*    <Form.Label htmlFor="username">User</Form.Label>*/}
                    {/*    <DropdownButton id="dropdown-item-button" title={loginUser}*/}
                    {/*                    onSelect={(eventKey) => handleDropdown(eventKey)}*/}
                    {/*                    variant="secondary"*/}
                    {/*                    className="w-100">*/}
                    {/*        <Dropdown.Item eventKey={DEMO_USER_DA} as="button" className="w-100">{DEMO_USER_DA}</Dropdown.Item>*/}
                    {/*        <Dropdown.Item eventKey={DEMO_USER_DBA} as="button" className="w-100">{DEMO_USER_DBA}</Dropdown.Item>*/}
                    {/*        <Dropdown.Item eventKey={DEMO_USER_1} as="button" className="w-100">{DEMO_USER_1}</Dropdown.Item>*/}
                    {/*        <Dropdown.Item eventKey={DEMO_USER_2} as="button" className="w-100">{DEMO_USER_2}</Dropdown.Item>*/}
                    {/*    </DropdownButton>*/}
                    {/*</Form.Group>*/}
                    <Form.Group>
                        <Form.Label htmlFor="username">Username</Form.Label>
                        <Form.Control
                            type="text"
                            id="username"
                            placeholder="Enter username"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            placeholder="Enter password"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        {loginStatus === USER_LOGIN_STATUS_FAILED && (
                            <Alert variant="danger">{loginError}</Alert>
                        )}
                        <Button variant="primary" type="submit" className="w-100">
                            {t('login')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Container>
    )
}