
import React, {useEffect, useState} from 'react';
import 'react-tooltip/dist/react-tooltip.css'

import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import styles from './Header.module.css';
import logo from './logo-ls-800.png'
import UserIcon from '../res/user.png'
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoggedIn, getUserEmail, getUserName, userLogOff} from "../features/user/userSlice";
import ToggleButton from "./ToggleButton";
import {COOKIE_USER_ID, DEMO_USER_DA, DEMO_USER_DBA, loggerDebug} from "./utils";
import Cookie from "js-cookie";
import {Button, Col, Container, Image, Row} from "react-bootstrap";

export default function Header() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isUserLoggedIn = useSelector(getIsLoggedIn)
    const userName = useSelector(getUserName)
    const userEmail = useSelector(getUserEmail)

    useEffect(() => {
        loggerDebug('Header isLoggedIn:' + isUserLoggedIn)
        if (!isUserLoggedIn) {
            navigate('/login')
        }
    }, []);

    const handleLogOff = (eventKey) => {
        dispatch(userLogOff({user:userName}));
    }

    const goToEditDashboard = (eventKey) => {
        navigate('/editDashboard')
    }

    const goToEditConnection = (eventKey) => {
        navigate('/editConnection')
    }

    return (
        <Container fluid>
            <Row className="align-items-center"> {/* 确保所有内容垂直居中 */}
                <Col xs="auto"> {/* logo 根据内容自动调整宽度 */}
                    <div className={styles.logoBox}>
                        <Image src={logo} fluid />
                    </div>
                </Col>
                {isUserLoggedIn && <Col xs="auto"> {/* username 根据内容自动调整宽度 */}
                    <div className={styles.usernameBox}>
                        <a>{userName}</a>
                    </div>
                </Col>}
                <Col className="flex-grow-1">

                </Col>
                {isUserLoggedIn && location.pathname !== '/editDashboard' && (userEmail === (DEMO_USER_DA + '@wvalley.ca')) && <Col xs="auto">
                    <div className={styles.logOffBox} style={{ textAlign: 'right' }}>
                        <a className={styles.button + '' + styles.blue} onClick={goToEditDashboard}>Edit Dashboard</a>
                    </div>
                </Col>
                }
                {isUserLoggedIn && location.pathname !== '/editConnection' && (userEmail === (DEMO_USER_DBA + '@wvalley.ca')) && <Col xs="auto">
                    <div className={styles.logOffBox} style={{ textAlign: 'right' }}>
                        <a className={styles.button + '' + styles.blue} onClick={goToEditConnection}>Edit Connections</a>
                    </div>
                </Col>
                }
                {isUserLoggedIn && <Col xs="auto"> {/* log off 根据内容自动调整宽度 */}
                    <div className={styles.logOffBox} style={{ textAlign: 'right' }}>
                        <a className={styles.button} onClick={handleLogOff}>Log off</a>
                    </div>
                </Col>}
            </Row>
        </Container>
    );
}