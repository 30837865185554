import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import axios from "axios";
import Cookie from 'js-cookie'

import {
    API_DOMAIN,
    loggerDebug,
    loggerError,
    removeUserFromCookie,
    storeUserToCookie,
    storeUUID,
    encryptPassword
} from "../../component/utils";

export const USER_LOGIN_STATUS_ANONYMOUS = 'user_login_ANONYMOUS'
export const USER_LOGIN_STATUS_PENDING = 'user_login_pending'
export const USER_LOGIN_STATUS_FAILED = 'user_login_failed'
export const USER_LOGIN_STATUS_SUCCEEDED = 'user_login_succeeded'
export const userLogin = createAsyncThunk('user/login', async ({user, pwd}) => {
    try{
        const response = await axios.post(API_DOMAIN + "/api/login", {
            user: user,
            tenantCode: 'VendastaDemo',
            email: user + '@wvalley.ca',
            password: pwd
        })

        return response.data
    } catch (e) {
        if (e.response) {
            // 获取 HTTP 响应的状态码
            const statusCode = e.response.status;
            /// {"data":null,"error":{"status":400,"name":"ApplicationError","message":"Email or Username are already taken","details":{}}}
            if (statusCode === 401) {
                return 'Failed to login: Password is wrong!'
            }
        } else {
            // eslint-disable-next-line no-undef
            return rejectWithValue("Failed to login: Unknown error")
        }
        // eslint-disable-next-line no-undef
        return rejectWithValue(e.toString())
    }
})

export const userLogOff = createAsyncThunk('user/logOff', async ({user: u}) => {
    try{
        loggerDebug('enter logoff')
        const response = await axios.post(API_DOMAIN + "/api/logout", {
            tenantCode: 'VendastaDemo',
            email: u + '@wvalley.ca'
        })

        return response.data
    } catch (e) {
        if (e.response) {
            loggerDebug(e.response.data.msg)
            // eslint-disable-next-line no-undef
            return rejectWithValue(e.response.data.msg)
        } else {
            return 'Unknown error'
        }
        // eslint-disable-next-line no-undef
        return rejectWithValue(e.toString())
    }
})

const initialState = {
    isLoggedIn: false,
    status: USER_LOGIN_STATUS_ANONYMOUS,
    loggedInError: '',
    userId: -1,
    userName: '',
    userEmail:''
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,

    reducers: {
        setUserInfo(state, action) {
            state.userName = action.payload.name
            state.isLoggedIn = true
            state.status = USER_LOGIN_STATUS_SUCCEEDED
        }
    },

    extraReducers(builder) {
        builder.addCase(userLogin.pending, (state, action) => {
            state.status = USER_LOGIN_STATUS_PENDING
        }).addCase(userLogin.rejected, (state, action) => {
            state.status = USER_LOGIN_STATUS_FAILED
            loggerDebug(action.payload)
            if (action.payload != null) {
                state.loggedInError = action.payload
            } else {
                state.loggedInError = "Failed to login: Unknown error."
            }
        }).addCase(userLogin.fulfilled, (state, action) => {
            const data = action.payload
            const result = action.payload.result
            if (data != null && result != null && result === 'success') {
                loggerDebug('login successfully')
                state.userName = data.user
                state.userEmail = data.email
                state.status = USER_LOGIN_STATUS_SUCCEEDED
                state.isLoggedIn = true
            } else {
                state.status = USER_LOGIN_STATUS_FAILED
                state.loggedInError = action.payload
            }
        }).addCase(userLogOff.pending, (state, action) => {

        }).addCase(userLogOff.rejected, (state, action) => {
            loggerDebug('log off failed but still reset')
            state.userName = ''
            state.status = USER_LOGIN_STATUS_ANONYMOUS
            state.isLoggedIn = false
        }).addCase(userLogOff.fulfilled, (state, action) => {
            const code = action.payload.code
            if (code === 0 ) {
                state.userName = ''
                state.status = USER_LOGIN_STATUS_ANONYMOUS
                state.isLoggedIn = false
            } else {
                loggerDebug('log off failed')
                state.userName = ''
                state.status = USER_LOGIN_STATUS_ANONYMOUS
                state.isLoggedIn = false
            }
        })
    }
})

export const {
    setUserInfo
} = userSlice.actions

export const getIsLoggedIn = (state) => state.user.isLoggedIn
export const getLoggedInStatus = (state) => state.user.status
export const getLoggedInError = (state) => state.user.loggedInError
export const getUserName = (state) => state.user.userName
export const getUserEmail = (state) => state.user.userEmail

export default userSlice.reducer;