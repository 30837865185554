import styles from './Footer.module.css'
import {Col, Row} from "react-bootstrap";

export default function Footer() {
    let today = new Date
    return (
        <Col>
            <div className="Footer">
                <p className={styles.copyright}>Copyright &copy; {today.getFullYear()} WisdomValley Ltd. All Rights Reserved.</p>
                <p className={styles.version}>Build v0.1.0801</p>
            </div>
        </Col>

    )
}
