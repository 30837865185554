
import Cookie from "js-cookie";

import { JSEncrypt } from 'jsencrypt';

export const COOKIE_USER_LOCATION_KEY = 'wv_user_location'
export const COOKIE_USER_LOCATION_LAT_KEY = 'wv_user_location_lat'
export const COOKIE_USER_LOCATION_LNG_KEY = 'wv_user_location_lng'

export const COOKIE_UUID = 'wv_uuid'
export const COOKIE_PUB_KEY = 'wv_pub_key'
export const COOKIE_USER_ID = 'wv_user_id'
export const COOKIE_USER_NAME = 'wv_user_name'
export const COOKIE_USER_EMAIL = 'wv_user_email'
export const COOKIE_USER_JWT = 'wv_user_jwt'

//Demo users
export const DEMO_USER_DA = 'agency'
export const DEMO_USER_DBA = 'vendasta-dba'


export const GOOGLE_MAP_API_KEY = 'AIzaSyCbqPT2J4-tLs2EKVaPd5Y9HS0-JL3H2N4'
export const API_DOMAIN = 'https://hengshi.junnan.wang'
export const EXPORT_SUFFIX = '?activeAuth=oauth2&tenantCode=VendastaDemo'

function searchFilterToString(searchFilter) {
    return "max_d-" + searchFilter.max_distance
}

export function generateSearchCacheKey(searchTerm, location, searchFilter, isSmartSearching, searchPlace) {
    const prefix = isSmartSearching ? 'smart-':('dumb-' + searchPlace)
    return prefix + '-' + searchTerm + '-' + location.lat.toString() + location.lng.toString() + '-' + searchFilterToString(searchFilter)
}

export function generateLocationCacheKey(location) {
    return location.lat.toString() + location.lng.toString()
}

export function loggerDebug(msg) {
    const time = new Date()
    console.debug("[" + time.toLocaleTimeString() + "] " + msg.toString())
}

export function loggerInfo(msg) {
    const time = new Date()
    console.log("[" + time.toLocaleTimeString() + "] " + msg.toString())
}

export function loggerError(error) {
    const time = new Date()
    console.error("[" + time.toLocaleTimeString() + "] " + error.toString())
}

export function validateUserName(uname) {
    if (uname == null) return false
    const regex = /^[A-Za-z](?=.*[A-Za-z\d])[A-Za-z\d]{2,}$/;
    return  regex.test(uname);
}

export function validateEmail(email) {
    const regex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    return  regex.test(email);
}


export  function storeUUID(uuid, publicKey) {
    Cookie.set(COOKIE_UUID, uuid, {expires: 14})
    Cookie.set(COOKIE_PUB_KEY, publicKey, {expires: 14})
}

export function storeUserToCookie(id, name, email, jwt) {
    Cookie.set(COOKIE_USER_ID, id, { expires: 14 })
    Cookie.set(COOKIE_USER_NAME, name, { expires: 14 })
    Cookie.set(COOKIE_USER_EMAIL, email, { expires: 14 })
    Cookie.set(COOKIE_USER_JWT, jwt, { expires: 14 })
}

export function removeUserFromCookie() {
    Cookie.remove(COOKIE_USER_ID)
    Cookie.remove(COOKIE_USER_JWT)
    Cookie.remove(COOKIE_USER_NAME)
    Cookie.remove(COOKIE_USER_EMAIL)
}


export function encryptPassword(publicKey, password) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const ep = encrypt.encrypt(password);
    return ep
}