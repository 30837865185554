import {Outlet, useLocation} from 'react-router-dom';
import Header from './Header';
import Footer from "./Footer";
import {useSelector} from "react-redux";

import Spinner from 'react-bootstrap/Spinner';
import {Container, Row} from "react-bootstrap";
import {getLoggedInStatus, USER_LOGIN_STATUS_PENDING} from "../features/user/userSlice";

const Layout = () => {
    const currentPage = useLocation().pathname
    const loginStatus = useSelector(getLoggedInStatus)

    let showLoading = false
    if (currentPage === '/login' && loginStatus === USER_LOGIN_STATUS_PENDING) {
        showLoading = true
    }

    let loadingClass = showLoading ? 'loader-container show':'loader-container hide'

    return (
        <Container fluid style={{padding: 0 + 'em'}}>
            <div className={loadingClass}>
                <Spinner animation="border" />
            </div>
            <Row>
                <Header/>
            </Row>
            <Row>
                <div style={{width: '100%', height:'0.5px', background: 'grey'}}/>
            </Row>
            <Row>
                <main className="App">
                    <Outlet/>
                </main>
            </Row>
            <Row>
                <Footer/>
            </Row>
        </Container>
    )
}

export default Layout