import React, {useEffect} from 'react';
import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom';
import Layout from "./component/Layout";
import Dashboard from "./features/entity/Dashboard";
import {COOKIE_USER_NAME, loggerDebug} from "./component/utils";
import {Login} from "./features/user/Login";
import Cookie from "js-cookie";
import {useDispatch} from "react-redux";
import {setUserInfo} from "./features/user/userSlice";
import EditDashboard from "./features/entity/EditDashboard";
import EditConnection from "./features/entity/EditConnection";

function App() {

    const dispatch = useDispatch()

    useEffect(() => {
        // read cookie to decide if user has logged in
        const userName = Cookie.get(COOKIE_USER_NAME)

        if (userName != null) {
            dispatch(setUserInfo({name:userName}))
        }
    }, []);

    return (
            <BrowserRouter>
                <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route path="login" element={<Login />} />

                            <Route index path="/" element={<Dashboard />} />

                            <Route path="editDashboard" element={<EditDashboard />} />
                            <Route path="editConnection" element={<EditConnection />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Route>
                </Routes>
            </BrowserRouter>

    );
}

export default App;
