import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            en: {
                translation: {
                    lang: "English",
                    km: "km",
                    oppositeLang: "中文",
                    clear: "Clear",
                    search_home: "Home: ",
                    scope_title: "Scope",
                    filter_title: "Filters",
                    search_scope: "Search Radius: ",
                    no_limit: "No limit",
                    search_placeholder: "Keywords such as art, music etc.",
                    search_placeholder_smart: "Looking for music class for my 5-year-old daughter",
                    user: "User",
                    locating: "Locating...",
                    search_loc_tooltip: "Searching in this city",
                    result_title:"Our recommendation",
                    related_institutions: "Related Institutions",
                    return: "return",
                    // user
                    user_reg_title: "Sign up",
                    user_info: "User Info",
                    user_name: "User Name: ",
                    user_pwd: "Password: ",
                    user_pwd_2nd: "Confirm Password: ",
                    user_reg_name_placeholder: "Username must be combination of letters and numbers and start with letters, at least 3 digits",
                    user_reg_pwd_placeholder: "At least 8 digits",
                    user_reg_pwd_2nd_placeholder: "Input your password again",
                    user_email: "Your email address",
                    user_reg_email_placeholder: "xxx@xx.com",
                    user_register_button: "Sign me up",
                    login: "Login",
                    user_no_account: "Don't have an account yet? ",
                    user_create_account: "Create an account",
                    user_info_confirm: "Confirm change",
                    user_old_pwd: "Old password:",
                    user_old_pwd_placeholder: "Please input the original password",
                    user_new_pwd: "New password:",
                    user_new_pwd_placeholder: "Please input the new password, at least 8 digits",
                    register_succeed: "Register successfully！Please return.",
                    user_info_logoff: "Log off",
                    //errors
                    error_locating_failure: "Location failure. Please input manually.",
                    error_no_id: "Please specify the entity ID",
                    error_location_is_not_ready: "Please input your address before searching",
                    browser_not_support_location: "browser_not_support_location",
                    error_geo_deny: "Please enable GeoLocation service to use this service.",
                    user_invalid_name: "Invalid user name.",
                    user_invalid_email: "Invalid email address.",
                    user_invalid_pwd_not_match: "Password doesn't match or it is less than 8 digits",
                    user_empty_pwd: "Old password can't be empty."
                }
            },
            zh: {
                translation: {
                    lang: "中文",
                    km: "公里",
                    oppositeLang: "English",
                    clear: "清除",
                    search_home: "家",
                    scope_title: "搜索范围",
                    filter_title: "搜索位置",
                    search_scope: "搜索半径: ",
                    no_limit: "无限",
                    search_placeholder: "关键词，比如: music, art",
                    search_placeholder_smart: "为我5岁的女儿寻找音乐课",
                    user: "用户",
                    locating: "定位中...",
                    search_loc_tooltip: "在该城市中搜索",
                    result_title:"我们的推荐",
                    related_institutions: "相关教育机构",
                    login: "登陆",
                    return: "返回",

                    // user
                    user_reg_title: "注册",
                    user_info: "用户信息",
                    user_name: "用户名: ",
                    user_pwd: "密码: ",
                    user_pwd_2nd: "确认密码: ",
                    user_reg_name_placeholder: "用户名必须是字母或者字母和数字的组合，以字母开头，最少3位",
                    user_reg_pwd_placeholder: "最少8位",
                    user_reg_pwd_2nd_placeholder: "再次输入密码",
                    user_email: "Email: ",
                    user_reg_email_placeholder: "xxx@xx.com",
                    user_register_button: "确认注册",
                    user_no_account: "还没有账号？",
                    user_create_account: "创建账号",
                    user_info_confirm: "确认修改",
                    user_old_pwd: "旧密码：",
                    user_old_pwd_placeholder: "请输入原始密码",
                    user_new_pwd: "新密码：",
                    user_new_pwd_placeholder: "请输入新的密码，最少8位",
                    register_succeed: "注册成功！请返回。",
                    user_info_logoff: "退出登录",
                    //errors
                    error_locating_failure: "定位失败，请手动输入地址",
                    error_no_id: "请输入教育机构ID",
                    error_location_is_not_ready: "请先输入地址再进行搜索",
                    browser_not_support_location: "browser_not_support_location",
                    error_geo_deny: "Please enable GeoLocation service to use this service.",
                    user_invalid_name: "无效的用户名",
                    user_invalid_email: "无效的邮箱",
                    user_invalid_pwd_not_match: "密码不匹配或者小于8位",
                    user_empty_pwd: "请输入密码"
                }
            }
        }
    });

export default i18n;