import styles from './Dashboard.module.css'
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {EXPORT_SUFFIX, loggerDebug} from "../../component/utils";
import {getIsLoggedIn} from "../user/userSlice";
import Iframe from 'react-iframe'


export default function Dashboard() {
    const navigate = useNavigate()

    const isUserLoggedIn = useSelector(getIsLoggedIn)

    useEffect(() => {
        if (!isUserLoggedIn) {
            navigate('/login')
        }
    })

    return (
        <div id="hsContainer" style={{width: "100%", height: "800px"}}>
            <Iframe
                frameBorder="0"
                importance="high"
                allowFullScreen="true"
                width="100%"
                height="100%"
                src={"https://bi.wvalley.ca/private/share/E57255E417A9F689A4D4EE3ED159EDFB/dashboard/E13E0C1AB547C7BA4C7A0CE5F1FC26D9D" + EXPORT_SUFFIX}>
            </Iframe>
        </div>
    )
}
